@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /* https://ui.jln.dev/theme/Dqz1daA2eZjjHGkk */
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 262.1 83.3% 57.8%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 262.1 83.3% 57.8%;
    --radius: 0.5rem;
  }

  /* https://ui.jln.dev/theme/6q42XU1RHQFgoAB2 */
  .dark {
    --background: 220 21% 16%;
    --foreground: 60 12% 95%;
    --muted: 220 12% 20%;
    --muted-foreground: 220 12% 70%;
    --popover: 220 21% 13%;
    --popover-foreground: 0 0% 100%;
    --card: 220 21% 14%;
    --card-foreground: 0 0% 100%;
    --border: 220 11% 21%;
    --input: 220 11% 24%;
    --primary: 40 100% 70%;
    --primary-foreground: 40 100% 10%;
    --secondary: 40 30% 25%;
    --secondary-foreground: 40 30% 85%;
    --accent: 220 21% 31%;
    --accent-foreground: 220 21% 91%;
    --destructive: 8 85% 51%;
    --destructive-foreground: 0 0% 100%;
    --ring: 40 100% 70%;
  }

  :root {
    --profit: 152 82% 39%;
    --loss: 0 100% 50%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  .pad {
    @apply px-4 md:px-0;
  }

  .edge-to-edge {
    @apply w-[calc(100%+2rem)] -mx-4 md:mx-0 md:w-full;
  }

  .card-edge-to-edge {
    @apply -mx-6 w-[calc(100%+3rem)];
  }

  /* required for sidebar to work */
  html,
  body {
    @apply h-full;
  }

  #sentry-feedback {
    @apply z-0;
  }

  #nprogress .bar {
    @apply !bg-primary;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px var(--primary), 0 0 5px var(--primary) !important;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  @media (max-width: 768px) {
    .scrollbar-hide {
      -webkit-overflow-scrolling: touch;
    }
  }
}